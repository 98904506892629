@if(tender){
    <div class="wrapper">
        <div class="meta-data-wrapper">
            <div class="row">
                <div class="form-group col">
                    <label for="tender-name">Offerte naam</label>
                    <div class="input-auto">
                        <input type="input" class="form-control" id="tender-name" maxlength="200" placeholder="Offerte naam" [(ngModel)]="tender.tenderName">
                        @if(automaticFillTenderName){
                            <label (click)="toggleAutomaticTenderName(false)" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Offertenaam automatisch bepalen">
                                <i class="bi bi-pencil-square"></i>
                            </label>
                        } @else {
                            <label (click)="toggleAutomaticTenderName(true)" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Offertenaam handmatig bepalen">
                                <i class="bi bi-hand-index-thumb"></i>
                            </label>
                        }
                    </div>
                </div>
                <div class="form-group col">
                    <label for="expiration-date">Vervaldatum</label>
                    <div class="input-auto">
                        <input type="date" class="form-control" id="expiration-date" [(ngModel)]="tender.expirationDate" [ngModel]="tender.expirationDate | date:'yyyy-MM-dd'">
                        @if(automaticFillExpirationDate){
                            <label (click)="toggleAutomaticExpirationDate(false)" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Vervaldatum automatisch bepalen">
                                <i class="bi bi-pencil-square"></i>
                            </label>
                        } @else {
                            <label (click)="toggleAutomaticExpirationDate(true)" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Vervaldatum handmatig bepalen">
                                <i class="bi bi-hand-index-thumb"></i>
                            </label>                         
                        }
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col">
                    <label for="tenderStatus">Status</label>
                    <div class="col status">
                        <select class="form-select" [(ngModel)]="tender.tenderStatus" (ngModelChange)="selectionChanged()">
                            @for(opt of tenderStatusItems; track opt.description){
                                <option [ngValue]="opt.value"><span>{{opt.description}}</span></option>
                            }
                        </select>
                        <label class="btn btn-secondary disabled anti-disabled" id="status-icon" [style.backgroundColor]="buttonColor">
                            <i class="{{selectedIconSource}}"></i>
                        </label>
                    </div>
                </div>
                <div class="form-group col deposit">
                    <div class="col deposit-group">
                        <label for="initial-deposit">Aanbetaling</label>
                        <div class="col deposit">
                            <input type="number" class="form-control" id="initial-deposit" [(ngModel)]="tender.initialDeposit">
                            <label class="btn btn-secondary disabled anti-disabled" [ngClass]="tender.initialDeposit && tender.initialDeposit >= 0 ? 'deposit-present' : 'no-deposit'">
                                @if(tender.initialDeposit && tender.initialDeposit >= 0){
                                    <i class="bi bi-check-circle"></i>
                                } @else if(!tender.initialDeposit || tender.initialDeposit < 0){
                                    <i class="bi bi-x-circle"></i>
                                }
                            </label>
                        </div>
                    </div>
                    @if(establishement?.defaultInitialDepositPercentage ?? 0 != 0){
                        <div class="col deposit-group">
                            <label>Aanbevolen o.b.v. {{establishement?.defaultInitialDepositPercentage ?? 0}}%</label>
                            <div class="col deposit">
                                <input class="form-control disabled" readonly id="initial-deposit-default" value="{{initialDepositAccordingToDefault}}" />
                                <label class="btn btn-secondary anti-disabled" (click)="showDefaultInitialDepositCalculation()">
                                    <i class="bi bi-info-circle"></i>
                                </label>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        <div class="fixed-values">
            <div class="row created-by">
                <div class="col group">
                    <span class="label">Aangemaakt door:</span>
                    <span>{{tender.createdBy}}</span>
                </div>
                <div class="col group">
                    <span class="label">Aangemaakt op:</span>
                    <span>{{tender.createdDate | date: 'dd-MM-yyyy - HH:mm' }}</span>
                </div>
            </div>
            @if(tender.lastModifiedBy){
                <div class="row updated-by">
                    <div class="col group">
                        <span class="label">Laatst geüpdate door:</span>
                        <span>{{tender.lastModifiedBy}}</span>
                    </div>
                    <div class="col group">
                        <span class="label">Gewijzigd op:</span>
                        <span>{{tender.lastModifiedDate | date: 'dd-MM-yyyy - HH:mm' }}</span>
                    </div>
                </div>
            }
            <div class="row created-by">
                @if(tender.statusChangedBy){
                    <div class="col group">
                        <span class="label">Statuswijziging door:</span>
                        <span>{{tender.statusChangedBy}}</span>
                    </div>
                }
                @if(tender.statusChangedDate){
                    <div class="col group">
                        <span class="label">Statuswijziging op:</span>
                        <span>{{tender.statusChangedDate | date: 'dd-MM-yyyy - HH:mm' }}</span>
                    </div>
                }
            </div>
            @if(tender.jgtVersion){
                <div class="row version">
                    @if(tender.statusChangedFromTo){
                        <div class="col group">
                            <span class="label">Statuswijziging:</span>
                            <span>{{tender.statusChangedFromTo}}</span>
                        </div>
                    }
                    <div class="col group">
                        <span class="label">Head4 versie:</span>
                        <span>{{tender.jgtVersion}}</span>
                    </div>
                </div>
            }
            @if(tender.uniqueTenderNumber){
                <div class="row version">
                    <div class="col group">
                        <span class="label">Offertenummer:</span>
                        <span>{{tender.uniqueTenderNumber}}</span>
                    </div>
                </div>
            }
        </div>
    </div>
}
