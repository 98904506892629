import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Models } from 'appwrite';
import { Subject, Subscription } from 'rxjs';
import { Api } from 'src/app/helpers/api';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { TaskService } from 'src/app/services/Task/task.service';
import { TeamsService } from 'src/app/services/Teams/teams.service';
import { Establishment } from 'src/app/shared/objects/establishment';
import { Task } from 'src/app/shared/objects/task';
import { Tender } from 'src/app/shared/objects/tender';
import { UserPreferences } from 'src/app/shared/objects/userPreferences';
import { ActiveTasksComponent } from '../../../dashboard/active-tasks/active-tasks.component';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ActiveTasksComponent,
  ],
  selector: 'app-manage-tasks',
  templateUrl: './manage-tasks.component.html',
  styleUrls: ['./manage-tasks.component.scss']
})
export class ManageTasksComponent implements OnInit, OnDestroy {

  @Input() tender?: Tender;
  @Input() saveTenderCallback?: () => void;
  @Input() user?: Partial<Models.User<UserPreferences>>;
  @Input() isViewer?: boolean;
  
  subscribtions: (Subscription | undefined)[] = []; 
  searchForUser?: string;
  showFeedback?: boolean;
  tasks: Subject<Task[]> = new Subject();
  shadowTaskList: Task[] = [];
  warningFeedback?: string;
  searchInput: string = "";
  sortedBy?: string;
  sortedAscending?: boolean;
  perPage: number;
  activePage: number;
  paginatedActiveTasks?: [Task[]];
  activeTeamId?: string;
  establishment?: Establishment;
  teamMembers?: Models.MembershipList;
  optionalMembers?: Models.Membership[];
  attachedMembers?: Models.Membership[];
  attachedUserIdsShallowList?: string;
  newTask: Task = {
    $id: "",
    creationDate: new Date(),
    status: "Te doen",
    tenderId: this.tender?.$id,
    archived: false,
    $collectionId: "",
    $createdAt: "",
    $databaseId: "",
    $permissions: [],
    $updatedAt: ""
  }

  constructor(public taskService: TaskService, private teamService: TeamsService, private internalCommunication: InternalCommunicationService) {
    this.perPage = 5;
    this.activePage = 0;
  }

  ngOnInit(): void {
    this.subscribtions.push(this.internalCommunication.userIsViewer?.subscribe(isViewer => {
      this.isViewer = isViewer;
    }));

    this.subscribtions.push(this.internalCommunication.activeEstablishment.subscribe(activeEstablishment => {
      this.establishment = activeEstablishment;

      Api.teams.list().then(teamsList => {
        var team = teamsList.teams.filter(t => t.name == activeEstablishment?.establishmentName);
        if (team?.length > 0) {
          this.activeTeamId = team[0].$id;

          this.teamService.getTeamMembers(team[0].$id).then(members => {
            this.teamMembers = members;
          });
        }
      });
    }));

    // Subscripe on changes
    this.subscribtions.push(this.tasks.subscribe(taskList => {
      this.shadowTaskList = taskList;
    }));

    if (this.tender) {
      this.taskService.getTasksOfTender(this.tender.$id!).then(tasks => {
        if (!tasks) {
          return;
        }

        this.tasks.next(tasks);
      });
    }

    this.subscribtions.push(this.internalCommunication.editTaskId.subscribe(taskId => {
      this.openTaskEditor(taskId);
    }));
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(s => s?.unsubscribe());
  }

  saveTenderAction(){
    if(this.saveTenderCallback){
      this.saveTenderCallback();
    }
  }

  openTaskEditor(taskId: string) {
    if (!taskId) {
      return;
    }

    var matchingTasks = this.shadowTaskList.filter(t => t.$id == taskId);
    if (matchingTasks.length > 0) {
      this.newTask = matchingTasks[0];

      // Find attached users
      var assignedUserIds = this.newTask.joinedAssignedUserIds?.split(',') ?? [];
      this.attachedMembers = this.getAttachedTeamMembers(assignedUserIds);
      this.attachedUserIdsShallowList = structuredClone(this.newTask.joinedAssignedUserIds);
    }
  }

  /**
   * This method will save a task for the current tender
   * @returns 
   */
  saveTask() {
    if (!this.newTask) {
      return;
    }

    if(!this.tender?.$id){
      this.warningFeedback = "Sla eerst de offerte op alvorens er taken kunnen worden gemaakt";
      return;
    }

    this.showFeedback = false;

    if(!this.newTask.taskName || !this.newTask.status){
      this.showFeedback = true;
      return;
    }

    // Check if task needs to be created or updated
    if (this.newTask.$id != "") {
      this.taskService.updateTask(this.newTask).then(result => {
        if (!result) {
          return;
        }

        this.optionalMembers = [];
        this.attachedMembers = [];

        // Update in list of tasks
        var updatedTask = this.shadowTaskList.filter(t => t.$id == this.newTask.$id);

        if (updatedTask.length > 0) {
          updatedTask[0].deadlineDate = this.newTask.deadlineDate;
          updatedTask[0].taskName = this.newTask.taskName;
          updatedTask[0].taskDescription = this.newTask.taskDescription;
          updatedTask[0].status = this.newTask.status;

          this.tasks.next(this.shadowTaskList);
        }

        // Prepare new Task
        this.createNewTask();

      })
    } else if(this.activeTeamId){
      // Set required values
      this.newTask.tenderId = this.tender?.$id;
      this.newTask.establishmentId = this.tender?.establishmentId;
      // Set attachedUserIds to null since all users are newly attached
      this.newTask.attachedUserIds = undefined;
      this.taskService.createTask(this.newTask, this.activeTeamId).then(result => {
        if (!result) {
          return;
        }

        this.optionalMembers = [];
        this.attachedMembers = [];

        var createdTask = result as Task;

        // List task in collection
        this.shadowTaskList.push(createdTask);
        this.tasks.next(this.shadowTaskList);

        // Prepare new Task
        this.createNewTask();
      })
    }
  }

  private createNewTask(){
    // Prepare new task
    this.newTask = {
      creationDate: new Date(),
      $id: "",
      status: "Te doen",
      tenderId: this.tender?.$id,
      establishmentId: this.tender?.establishmentId,
      archived: false,
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: ""
    }

    this.attachedUserIdsShallowList = undefined;
  }

  /**
   * Find all user which can be added to the new task
   * @returns 
   */
  attachedUserInputChanged(){
    if(!this.searchForUser || !this.teamMembers){
      this.optionalMembers = [];
      return;
    }

    var currentlyAssigned = this.newTask.joinedAssignedUserIds?.split(",") ?? [];
    this.optionalMembers = this.teamMembers.memberships?.filter(tm => !currentlyAssigned.includes(tm.userId) && (tm.userName.includes(this.searchForUser ?? "") || tm.userEmail.includes(this.searchForUser ?? "")));
  }

  /**
   * This method will attach a user to the new task
   * @param member 
   */
  attachUser(member: Models.Membership){
    if(!this.newTask || !member){
      return;
    }

    if(!this.newTask.joinedAssignedUserIds){
      this.newTask.joinedAssignedUserIds = `${member.userId}`;
      this.optionalMembers = this.optionalMembers?.filter(m => m.userId != member.userId) ?? [];
    } else if(!this.newTask.joinedAssignedUserIds.includes(member.userId)){
      var assignedUsers = this.newTask.joinedAssignedUserIds.split(",");
      assignedUsers.push(member.userId);
      this.newTask.joinedAssignedUserIds = assignedUsers.join(",");
      this.optionalMembers = this.optionalMembers?.filter(m => m.userId != member.userId) ?? [];
    }

    this.attachedMembers = this.getAttachedTeamMembers(this.newTask.joinedAssignedUserIds.split(","));
    // Fill the AttachedUserIds with all new attached user so that a notfication can be send
    this.newTask.attachedUserIds = this.newTask.joinedAssignedUserIds.split(",").filter(u => !this.attachedUserIdsShallowList?.includes(u)).join(",");
  }

    /**
   * This method will detach a user from the new task
   * @param member 
   */
    detachUser(member: Models.Membership){
      if(!this.newTask || !member){
        return;
      }
  
      var assignedUsers = this.newTask.joinedAssignedUserIds?.split(",");
      this.newTask.joinedAssignedUserIds = assignedUsers?.filter(u => u != member.userId).join(",");

      // Add to optional list of matches filter
      if(this.searchForUser && (member.userEmail.includes(this.searchForUser) || member.userName.includes(this.searchForUser))){
        if(!this.optionalMembers){
          this.optionalMembers = [];
        }
        this.optionalMembers?.push(member);
      }

      this.attachedMembers = this.getAttachedTeamMembers(this.newTask.joinedAssignedUserIds?.split(",") ?? []);
      // Fill the AttachedUserIds with all new attached user so that a notfication can be send
      this.newTask.attachedUserIds = this.newTask.joinedAssignedUserIds?.split(",").filter(u => !this.attachedUserIdsShallowList?.includes(u)).join(",");
    }

  /**
   * This method will return a list of all TeamMembers based on the given ids
   * @param userIds 
   */
  getAttachedTeamMembers(userIds: string[]){
    if(!userIds || !this.teamMembers){
      return [];
    }

    return this.teamMembers.memberships.filter(ms => userIds.includes(ms.userId));
  }

  clearNewTask() {
    // Prepare new task
    this.newTask = {
      creationDate: new Date(),
      $id: "",
      status: "",
      tenderId: this.tender?.$id,
      establishmentId: this.tender?.establishmentId,
      archived: false,
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: ""
    }

    this.optionalMembers = [];
    this.attachedMembers = [];
  }

  /**
   * Add task values by quick actions
   */
  quickAction(type: string) {
    if (type == "deposit") {
      this.newTask = {
        creationDate: new Date(),
        $id: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
        archived: false,
        status: "Te doen",
        tenderId: this.tender?.$id,
        establishmentId: this.tender?.establishmentId,
        taskName: "Aanbetalingsverzoek versturen",
        taskDescription: "Voor deze offerte dient een aanbetalingsverzoek te worden gestuurd door de administratie."
      }
    } else if (type == "print sheet") {
      this.newTask = {
        creationDate: new Date(),
        $id: "",
        status: "Te doen",
        tenderId: this.tender?.$id,
        establishmentId: this.tender?.establishmentId,
        taskName: "Sheet printen",
        archived: false,
        taskDescription: "De sheet dient te worden geprint voor de keuken.",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: ""
      }
    } else if (type == "recieve consonance") {
      this.newTask = {
        creationDate: new Date(),
        $id: "",
        status: "Te doen",
        tenderId: this.tender?.$id,
        establishmentId: this.tender?.establishmentId,
        taskName: "Akkoord ontvangen op de offerte",
        archived: false,
        taskDescription: "Er dient een akkoord te worden gegeven op de verstuurde offerte.",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: ""
      }
    }
  }
}
